import axios from 'axios';

const baseAPI = "https://coelho-backend.herokuapp.com/api"

let submit = document.getElementById("Submit");
submit.addEventListener('click', login);

let userId = 0;

async function login() {
    let email = document.getElementById("email");
    let password = document.getElementById("password");
    let body = {
        "identifier": email.value,
        "password": password.value
    }
    console.log(body)
    let send = await axios.post(baseAPI + "/auth/local", body).catch( (error) => setFeedback(JSON.stringify(error)))
    localStorage.setItem("jwt", send.data.jwt)
    if(send.status == 200) {
        setFeedback("")
        document.getElementById("search").style.display = "flex";
        document.getElementById("login").style.display = "none";
    } else {
        setFeedback(JSON.stringify(send))
    }
    return false
}

let check = document.getElementById("search-cpf");
check.addEventListener('click', checkUser);
let searchForm = document.getElementById("search-form");
searchForm.onsubmit = function () {
    checkUser()
    return false
};
async function checkUser() {
    setFeedback("")
    document.getElementById("found-cpf").innerHTML = ""
    document.getElementById("found-name").innerHTML = ""
    document.getElementById("found-updated").innerHTML = ""
    document.getElementById("found-created").innerHTML = ""
    document.getElementById("found-validated").innerHTML = ""
    let cpf = document.getElementById("cpf");
    let jwt = localStorage.getItem("jwt")
    let headers = {
        "Authorization": "Bearer " + jwt
    }
    let send = await axios.get(baseAPI + '/codes?filters[code][$eq]=' + cpf.value, {headers}).catch( (error) => setFeedback(JSON.stringify(error)))
    if(send.status == 200) {
        let userData = send.data.data
        console.log(userData)
        if(userData.length > 0) {
            let c = new Date(userData[0].createdAt)
            let u = new Date(userData[0].updatedAt)
            userId = userData[0].documentId
            document.getElementById("found-cpf").innerHTML = userData[0].code
            document.getElementById("found-name").innerHTML = userData[0].name + " " + userData[0].surname
            document.getElementById("found-updated").innerHTML = u
            document.getElementById("found-created").innerHTML = c
            document.getElementById("found-validated").innerHTML = userData[0].received ? "sim" : "não"
            document.getElementById("validate").style.display = userData[0].received ? "none" : "block"
            document.getElementById("found-code").innerHTML = "Procurando código..."
        } else {
            setFeedback("CPF não encontrado")
        }
    }
    return false
}

let validate = document.getElementById("validate");
validate.addEventListener('click', validateUser);

async function validateUser() {
    setFeedback("")
    let jwt = localStorage.getItem("jwt")
    let headers = {
        "Authorization": "Bearer " + jwt
    }
    let send = await axios.put(baseAPI + '/codes/' + userId, {"data": {"received": true}}, {headers}).catch( (error) => setFeedback(JSON.stringify(error)))
    if(send.status == 200) {
        document.getElementById("validate").style.display = "none"
        document.getElementById("found-validated").innerHTML = "True"
        setFeedback("Brinde validado")
    }
}

function setFeedback(message) {
    document.getElementById("feedback").innerHTML = message
}